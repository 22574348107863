






























































































































































































































































































.index {
  box-sizing: border-box;
  height: 100%;
  padding: 20px 20px 0;
}
.resources-content {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.table {
  margin-top: 30px;
}
.goods-wrapper {
  display: flex;
  align-items: center;
  cursor: pointer;
  .goods-cover {
    width: 50px;
    height: 50px;
    display: flex;
    background: #f6f6f6;
    border-radius: 8px;
    img {
      max-width: 100%;
      max-height: 100%;
      margin: auto;
    }
  }
  .goods-name {
    //   width: 1%;
    //   flex: 1;
    margin-left: 10px;
    font-size: 14px;
  }
}
.scro {
  height: 100%;
  padding-top: 30px;
  .el-scrollbar {
    height: 100%;
  }
}
.pages-center {
  margin: 20px 0 20px;
  text-align: center;
}
::v-deep .customTable th:first-child > .cell {
  padding-left: 30px;
}
::v-deep .customTable td:first-child > .cell {
  padding-left: 30px;
}
